import React, { useEffect } from 'react';
import {
  Button,
  Card,
  Descriptions,
  Divider,
  Form,
  Input,
  Radio,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useKeycloak } from '@react-keycloak/web';
import TimezonePicker from '../components/shared/TimezonePicker/TimezonePicker';
import { useSelector } from 'react-redux';
import {
  appActions,
  getDateFormat,
  getRdpConnection,
  getStatus,
  getTimeFormat,
  getUser,
} from '../store/slices/app/selectors';
import { updateUser } from '../store/slices/app/extraReducers';
import {
  DateFormat,
  DateFormatCookie,
  RdpConnection,
  RdpConnectionCookie,
  TimeFormat,
  TimeFormatCookie,
} from '../model/interfaces/app';
import { Status } from '../model/enum/Status';
import { useAppDispatch } from '../store';
import useNotification from '../hooks/useNotification';

const { Title } = Typography;

const Profile = () => {
  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const dispatch = useAppDispatch();
  const { keycloak } = useKeycloak();
  const { successNotification } = useNotification();
  const user = useSelector(getUser);
  const status = useSelector(getStatus);
  const timeFormat = useSelector(getTimeFormat);
  const dateFormat = useSelector(getDateFormat);
  const rdpConnection = useSelector(getRdpConnection);

  useEffect(() => {
    if (status === Status.SUCCESS) {
      passwordForm.resetFields();
    }
  }, [status]);

  const savePersonalInfo = (values: any) => {
    dispatch(
      updateUser({
        id: keycloak.subject,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
      })
    ).then(() => successNotification('User updated'));
  };

  const changeTimeFormat = (e: any) => {
    localStorage.setItem(TimeFormatCookie, e.target.value);
    dispatch(appActions.setTimeFormat(e.target.value));
  };

  const changeDateFormat = (e: any) => {
    localStorage.setItem(DateFormatCookie, e.target.value);
    dispatch(appActions.setDateFormat(e.target.value));
  };

  const changeRdpConnection = (e: any) => {
    localStorage.setItem(RdpConnectionCookie, e.target.value);
    dispatch(appActions.setRdpConnection(e.target.value));
  };

  const checkPasswordMatch = () => {
    return (
      passwordForm.getFieldValue('confirmNewPassword') === passwordForm.getFieldValue('password')
    );
  };

  return (
    <Spin spinning={status === Status.LOADING}>
      <Card>
        <Form
          style={{ maxWidth: '600px' }}
          form={form}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          labelAlign='left'
          onFinish={savePersonalInfo}
          initialValues={user}>
          <div>
            <Title level={4}>Personal Information</Title>
            <div className='outer'>
              <Form.Item label='First name' name='firstName' colon={false}>
                <Input />
              </Form.Item>
              <Form.Item label='Last name' name='lastName' colon={false}>
                <Input />
              </Form.Item>
              <Form.Item label='Username' name='username' colon={false}>
                <Input disabled />
              </Form.Item>
            </div>
            <div className='flex-center'>
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type='primary'
                    htmlType='submit'
                    disabled={
                      !form.isFieldsTouched(['firstName', 'lastName']) ||
                      !!form.getFieldsError().filter(({ errors }) => errors.length).length ||
                      (form.getFieldValue('firstName') === user?.firstName &&
                        form.getFieldValue('lastName') === user?.lastName)
                    }>
                    Save changes
                  </Button>
                )}
              </Form.Item>
            </div>
          </div>
        </Form>

        <Form
          form={passwordForm}
          style={{ maxWidth: '600px' }}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          labelAlign='left'
          onFinish={savePersonalInfo}>
          <Form.Item
            label='New password'
            name='password'
            rules={[{ whitespace: true }]}
            colon={false}>
            <Input.Password />
          </Form.Item>
          <Form.Item
            label='Confirm new password'
            name='confirmNewPassword'
            dependencies={['password']}
            rules={[
              { message: 'Please confirm your password!', whitespace: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The two passwords that you entered do not match!')
                  );
                },
              }),
            ]}
            hasFeedback
            colon={false}>
            <Input.Password onChange={checkPasswordMatch} />
          </Form.Item>

          <div className='flex-center'>
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={
                    !passwordForm.isFieldsTouched(['password', 'confirmNewPassword'], true) ||
                    !!passwordForm.getFieldsError().filter(({ errors }) => errors.length).length ||
                    passwordForm.getFieldValue(['password']) === '' ||
                    passwordForm.getFieldValue(['confirmNewPassword']) === ''
                  }>
                  Change password
                </Button>
              )}
            </Form.Item>
          </div>
        </Form>
        <Divider />
        <Form
          style={{ maxWidth: '600px' }}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          labelAlign='left'>
          <Title level={4}>Timezone and format</Title>
          <Form.Item label='Time zone' colon={false}>
            <TimezonePicker />
          </Form.Item>
          <Form.Item label='Time format' colon={false}>
            <Radio.Group value={timeFormat} onChange={changeTimeFormat}>
              <Space direction='vertical'>
                <Radio value={TimeFormat.H24}>Use a 24 hour format</Radio>
                <Radio value={TimeFormat.H12}>Use a 12 hour format [AM/PM]</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item label='Date format' colon={false}>
            <Radio.Group value={dateFormat} onChange={changeDateFormat}>
              <Space direction='vertical'>
                <Radio value={DateFormat.DOT_SEPARATED}>dd.mm.yyyy [20.06.2001]</Radio>
                <Radio value={DateFormat.SLASH_SEPARATED}>mm/dd/yyyy [06/20/2001]</Radio>
                <Radio value={DateFormat.DASH_SEPARATED}>yyyy-mm-dd [2001-06-20]</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Divider />
          <Title level={4}>Device Access</Title>
          <Form.Item label='Remote Desktop access' colon={false}>
            <Radio.Group value={rdpConnection} onChange={changeRdpConnection}>
              <Space direction='vertical'>
                <Radio value={RdpConnection.RDP}>Use native RDP client</Radio>
                <Radio value={RdpConnection.HTML5}>Use HTML5</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <p>If you choose native RDP, you need to have these ports open.</p>
          <Descriptions layout='vertical' bordered>
            <Descriptions.Item label='Network address'>193.110.145.0/24</Descriptions.Item>
            <Descriptions.Item label='TCP port(s)'>443, 2100-2300</Descriptions.Item>
          </Descriptions>
        </Form>
      </Card>
    </Spin>
  );
};

export default Profile;
